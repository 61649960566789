<!-- <template>
  <div class="mdl_main_container">
    <div class="mdl_main">
      <b-button
        id="imprt_mdl_btn"
        :to="{ name: 'module_import' }"
        title="Lab Definition"
        variant="success"
        class="mb-4"
      >
        <font-awesome-icon icon="save" class="" />
        Define Lab Infrastructure
      </b-button>
      <div class="card-layout">
        <b-card v-for="module in modules" :key="module.id" :header-class="module.mainProvider || 'unknown'"
          class="moduleCard" no-body>
          <template v-slot:header>
            <h1>{{ module.name }}</h1>
            <app-provider-logo :provider="module.mainProvider" size="40px" />
          </template>
          <b-card-body>
            <p>{{ module.description }}</p>
          </b-card-body>
          <b-card-footer class="metadata">
            <app-cli-badge class="app-cli-badge" :cli="module.terraformImage" badge-style="flat-square" />
            <p v-if="module.estimatedMonthlyCost">
              <font-awesome-icon icon="dollar-sign" /> {{ module.estimatedMonthlyCost }} / month
            </p>
            <p><font-awesome-icon icon="user" /> {{ module.moduleMetadata.createdBy.username }} </p>
          </b-card-footer>
          <b-card-footer>
            <b-button :to="{ name: 'module', params: { moduleId: module.id } }"
             title="Edit this module" variant="primary"
              class="mr-1">
              <font-awesome-icon icon="edit" />
            </b-button>
            <b-button :to="{ name: 'module_description', params: { moduleId: module.id } }"
             title="Detail of this module"
              variant="primary" class="mr-1">
              <font-awesome-icon icon="info" />
            </b-button>
            <b-button title="Run this module" variant="primary" class="mr-1" @click="createStack(module.id)">
              <font-awesome-icon icon="rocket" />
            </b-button>
          </b-card-footer>
        </b-card>
      </div>
      <b-table
        id="mdlTable"
        responsive
        :items="modules"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        :per-page="perPage"
        :current-page="currentPage"
        small

        @filtered="onFiltered"
      >
        <template v-slot:cell(providerlogo)="row">
          <app-provider-logo
            :provider="row.value"
            size="40px"
          />
        </template>
        <template v-slot:cell(name)="row">
          <span>{{ row.value }}</span>
        </template>
        <template v-slot:cell(edit)="row">
          <span>
            <b-button
              :to="{ name: 'module', params: { moduleId: row.item.id } }"
              title="Edit this module"
              class="btn_mdl_list"
              variant="primary"
            >
              <font-awesome-icon
                icon="edit"
                class="icon"
              />
            </b-button>
          </span>
        </template>
        <template v-slot:cell(detail)="row">
          <span>
            <b-button
              :to="{ name: 'module_description', params: { moduleId: row.item.id } }"
              title="Detail of this module"
              class="btn_mdl_list"
              variant="primary"
            >
              <font-awesome-icon icon="info" />
            </b-button>
          </span>
        </template>
        <template v-slot:cell(run)="row">
          <span>
            <b-button
              title="Run this module"
              variant="primary"
              class="btn_mdl_list"
              @click="createStack(row.item.id)"
            >
              <font-awesome-icon icon="rocket" />
            </b-button>
          </span>
        </template>
      </b-table>
    </div>
  </div>
</template> -->
<template>
  <div
  class="modules_main"
  >
    <div
    class="module_table"
    >
    <b-button
        id="imprt_mdl_btn"
        :to="{ name: 'module_import' }"
        title="Create Lab Definition"
        variant="success"
        class="mb-4"
      >
        <!-- <font-awesome-icon icon="save" class="" /> -->
        Define Lab Infrastructure
      </b-button>
    <Mastertable
      :items="modules"
      :fields="fields"
      @detail-clicked="handleDetailClicked"
      @edit-clicked="handleEditClicked"
      @run-clicked="handleRunClicked"
      />
    </div>
  </div>
</template>

<script>
  import { getModules } from '@/shared/api/modules-api';
  import
  { // AppCliBadge,
    AppProviderLogo,
  } from '@/shared/components';
import Mastertable from '../../shared/components/mastertable/mastertable.vue';

  export default {
    name: 'AppModules',
    components: {
    // AppCliBadge,
    AppProviderLogo,
    Mastertable,
},

    data: function data() {
      return {
        modules: [],
        fields: [
          // { key: 'providerlogo', label: 'Provider' },
          { key: 'name', label: 'Lab Name' },
          { key: 'description', label: 'Infra Description' },
          { key: 'edit-1', label: 'Edit' },
          { key: 'detail', label: 'Detail' },
          { key: 'run', label: 'Run' },
        ],
      };
    },
    computed: {
      // rows() {
      //   return this.items.length;
      // },
    },

    async created() {
      this.modules = await getModules();
    },

    methods: {
      handleDetailClicked(module) {
      this.$router.push({
        name: 'module_description',
        params: {
          moduleId: module.id,
        },
      });
    },
    handleEditClicked(module) {
      this.$router.push({
        name: 'module',
        params: {
          moduleId: module.id,
        },
      });
    },
    handleRunClicked(moduleId) {
      this.$router.push({
        name: 'stack_creation',
        params: {
          moduleId,
        },
      });
    },
  },
  };

</script>
<style scoped>
.form-row legend{
  display: none;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  /* border-color: #007bff; */
}
p {
  margin-bottom: 0;
}
.input-group.mb-3 {
  width: 80%;
}

.card-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card {
  width: 30%;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.card-header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.card-header h1 {
  color: white;
}

.card-header.unknown {
  background-color: #FF9900;
}

.card-header.google {
  background-color: #FF9900;
}

.card-header.docker {
  background-color: #FF9900;
}

.card-header.aws {
  background-color: #FF9900;
}

.card-header.azurerm {
  background-color: #FF9900;
}

.btn_mdl_list {
  background-color: #0069d9;
}

.metadata {
  display: flex;
  justify-content: space-between;
}

.app-cli-badge {
  height: 100%;
  margin-right: 0.75rem;
}

p.module_name {
  font-size: 17px;
  color: #242121;
  font-weight: 500;
}

#imprt_mdl_btn {
  border-radius: 3rem;
  color: white;
  overflow: hidden;
  padding: 5px 10px;
  transition: .10s transform ease-in-out;
  will-change: transform;
  z-index: 0;
  background-color: #065090;
}
#imprt_mdl_btn::after{
background-color: #ECAA0D;
border-radius: 5rem;
content: '';
display: block;
height: 100%;
width: 100%;
position: absolute;
left: 0;
top: 0;
transform: translate(-100%, 0) rotate(10deg);
transform-origin: top left;
transition: 0.3s transform ease-out;
will-change: transform;
z-index: -1;
}

#imprt_mdl_btn:hover::after{
transform: translate(0, 0);
}
#imprt_mdl_btn:hover{
color: white;
transform :scale(1.05);
will-change: transform;
}
.module_table{
  width: 90%;
  margin: auto;
  padding: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid #dadce0;
  border-radius: 20px;
  background-color: white;
  margin-bottom: 20px;
}
@media only screen and (max-width: 480px) {
  .module_table{
    width: 100%;
    box-shadow: none;
    border: none;
    border-radius: 0%;
    background-color: white;
    }
  }
  @media only screen and (max-width: 768px) {
    .module_table{
      width: 100%;
      box-shadow: none;
      border: none;
      border-radius: 0%;
      background-color: white;
      }
    }
</style>
